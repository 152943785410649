import request from '@/utils/requests';
import { countries } from 'countries-list';

const getters = {
  getCountries: (state) => state.countries,
};

const mutations = {
  setCountries: (state, countriesList) => {
    const updatedCountries = countriesList.map((countryCode) => {
      const matchingCountry = countries[countryCode.toUpperCase()];

      if (matchingCountry) {
        return {
          name: matchingCountry.name,
          code: countryCode,
        };
      }
      return null;
    });

    state.countries = updatedCountries;
  },
};

const actions = {
  fetchCountries: async ({ commit, rootGetters }) => {
    try {
      const response = await request(
        'GET',
        'api/v2/materials/country-codes',
        rootGetters['auth/getToken'],
        {},
      );
      commit('setCountries', response.data);
      return Promise.resolve(response);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
};

const state = {
  countries: [],
};

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state,
};
