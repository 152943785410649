<template>
  <v-app id="app">
    <nav-bar v-if="isItLanding"/>
    <demo-banner v-if="isDemoApp"/>
    <authenticated-nav v-if="isAuthenticatedUser"/>
    <admin-nav v-else-if="isAuthenticatedAdmin"/>
    <v-main :class="isItLanding ? 'pa-0 ma-0' : ''">
      <snack-bar ref="snackBar"/>
      <router-view/>
      <app-footer v-if="isItLanding"/>
    </v-main>
  </v-app>
</template>

<script>
import AuthenticatedNav from '@/components/authenticated/navigation/AuthenticatedNav.vue';
import SnackBar from '@/components/shared/SnackBar.vue';
import { mapGetters } from 'vuex';
import AdminNav from '@/components/admin/AdminNav.vue';
import AppFooter from '@/components/footer/AppFooter.vue';
import NavBar from '@/components/navigation/NavBar.vue';
import axios from 'axios';
import DemoBanner from '@/components/demo/DemoBanner.vue';

export default {
  name: 'App',
  components: {
    DemoBanner,
    NavBar,
    AppFooter,
    AdminNav,
    AuthenticatedNav,
    SnackBar,
  },

  computed: {
    isItLanding() {
      return this.$route.name === 'landing';
    },
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      authStatus: 'auth/authStatus',
      isAdmin: 'auth/isAdmin',
      getToken: 'auth/getToken',
      isDemoApp: 'demo/isDemoApp',
    }),

    isAuthenticatedUser() {
      return this.isDemoApp ? true : this.isAuthenticated && !this.isAdmin;
    },

    isAuthenticatedAdmin() {
      return this.isAuthenticated && this.isAdmin;
    },
  },

  data() {
    return {
      loadingSocket: false,
    };
  },

  mounted() {
    this.$root.snackBar = this.$refs.snackBar;
    axios.interceptors.response.use((response) => response, (error) => {
      if (error.response.status === 401 && (this.$router.currentRoute.meta.requiresAuth
                                            || this.$router.currentRoute.meta.requiresAdminAuth)) {
        this.$store.dispatch('auth/LOGOUT');
        this.$router.push('/login').catch(() => {});
        return Promise.reject(new Error(this.$t('authentication.session_expired')));
      }
      return Promise.reject(error);
    });
  },
};
</script>

<style lang="scss">
@import "./styles/_app.scss";
@import "./styles/_typography.scss";
@import "./styles/_colors.scss";

#app {
  //background-color: $srpls-background;
}
html {
  overflow-y: auto !important;
}
</style>
