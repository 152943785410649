import Vue from 'vue';
import Vuex from 'vuex';

import countries from './modules/countries';
import notifications from './modules/notification/notifications';
import auth from './modules/auth';
import register from './modules/register';
import materials from './modules/material/materials';
import materialGroups from './modules/material_group/materialGroups';
import admin from './modules/admin/admin';
import companies from './modules/company/companies';
import offer from './modules/offer/offer';
import chat from './modules/chat/chat';
import exchanges from './modules/exchange/exchanges';
import users from './modules/user/users';
import demo from './modules/demo';
import recommendedMaterials from './modules/material/recommendedMaterials';

import locale from './modules/locale'; // Import the locale module

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    admin,
    auth,
    chat,
    companies,
    countries,
    demo,
    exchanges,
    locale,
    materialGroups,
    materials,
    notifications,
    offer,
    recommendedMaterials,
    register,
    users,
  },
});
