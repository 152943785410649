<template>
  <router-link
      :to="routeName"
      class="d-none d-md-flex flex-md-column align-center justify-space-between
              ms-12 text-decoration-none"
  >
    <v-hover v-slot="{ hover }">
      <span
          :style="hover ? 'color: white' : 'color: grey'"
          class="font-family-montserrat"
      >
        {{ routeText }}
      </span>
    </v-hover>
    <v-img
        :src="this.$route.path === routeName ? activeLinkArrow : inactiveLinkArrow"
        class="img-fluid"
        :style="this.$route.path === routeName
                ? 'max-width: 80%; bottom: -10px; background-color: #141919;'
                : 'max-width: 80%;'"
    ></v-img>
  </router-link>
</template>

<script>
import activeLinkArrow from '@/assets/nav_link_arrow_active.svg';
import inactiveLinkArrow from '@/assets/nav_link_arrow_inactive.svg';

export default {
  name: 'CustomNavLink',
  props: ['routeName', 'routeText'],
  data() {
    return {
      activeLinkArrow,
      inactiveLinkArrow,
    };
  },
};
</script>

<style lang="scss" scoped>
.router-link-active > span:nth-child(2) {
  color: white !important;
}
</style>
